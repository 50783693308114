/*

Template 2095 Level

http://www.tooplate.com/view/2095-level

*/

@keyframes animatedBackground {
	from {
		background-position: 0 20%;
	}
	to {
		background-position: 0 80%;
	}
}

body {
	font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
	font-size: 15px;
	font-weight: 400;
	overflow-x: hidden;
}

a, button { transition: all 0.3s ease; }
a:hover,
a:focus {
	text-decoration: none;
	outline: none;
}

.tm-position-relative { position: relative; }
.tm-flex-align-center { align-items: center; }
.tm-fa-6x { font-size: 6em; }
.tm-margin-b-0 { margin-bottom: 0; }
.tm-margin-b-20 { margin-bottom: 20px; }
.tm-p-4 { padding: 2rem!important; }
.tm-color-white { color: white; }
.tm-color-primary {	color: #006666; }
.tm-bg-primary { background: #006666; }
.tm-bg-gray { background: #F4F4F4; }
.tm-bg-white { background: white; }
.tm-bg-dark-blue { background: #1f3646; }

.tm-bg-white-shadow {
	-webkit-box-shadow: 0px 0px 7px 0px rgba(214,214,214,1);
	-moz-box-shadow: 0px 0px 7px 0px rgba(214,214,214,1);
	box-shadow: 0px 0px 7px 0px rgba(214,214,214,1);
}

.tm-section-pad { padding: 30px 50px; }
.tm-section-pad-2 { padding: 20px 30px 5px; }
.tm-article-pad { padding: 28px; }
.tm-sidebar-pad { padding: 15px 20px; }
.tm-sidebar-pad-2 { padding: 21px 20px; }
.tm-pad { padding: 20px; }

a.tm-color-primary:hover,
a.tm-color-primary:active {
	color: #006666;
}

.tm-font-light { font-weight: 300; }
.tm-font-normal { font-weight: 400; }
.tm-font-semibold { font-weight: 600; }

p { 
	color: #2a2a2a; 
	line-height: 1.9;
}

.navbar { 
	width: 100%; 
	padding-top: 0;
	padding-bottom: 0;
}
.navbar-brand {
	font-size: 1.8rem;
	font-weight: 700;
	margin-right: 0;
}
.navbar a { color: black; }

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 102, 102, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-toggler { 
	border-color: #006666; 
	cursor: pointer;
}

.nav-item {
	background: transparent;
	border-left: 1px solid #ccc;
	cursor: pointer;
	font-size: 0.8rem;
	font-weight: 300;
    text-transform: uppercase;
    padding: 0;
    transition: all 0.5s ease;
}

.nav-item:last-child { border-right: 1px solid #ccc; }

.nav-link.active,
.nav-link:hover {
	color: white;
	background: #006666;	
}

.tm-top-bar .navbar-expand-lg .navbar-nav .nav-link { padding: 15px 25px; }

.tm-top-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    z-index: 10000;
    transition: all 0.2s ease-in-out;
    height: 58px;
	background: white;
	
	/* Delete below if design is not appreciated */
	border-bottom: 2px solid #006666;
    -webkit-box-shadow: -2px -22px 15px 24px rgba(0, 102, 102, 0.67);
    -moz-box-shadow: -2px -22px 15px 24px rgba(0, 102, 102, 0.67);
    box-shadow: -2px -22px 15px 24px rgba(0, 102, 102, 0.67);
}

.tm-top-bar.active {
	height: 58px;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
}

.tm-top-bar.active .navbar-expand-lg .navbar-nav .nav-link { padding: 30px 35px; }
.tm-top-bar-bg { height: 58px; }

.tm-top-bar .header-slogan{
	font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 0px;
    padding-top: 8px;
}

.tm-section-pad {
	padding-top: 80px;
	padding-bottom: 80px;
}

#tm-section-1 {	
	position: relative;
	background-image: url('img/opelrock.jpg');	
	background-position: top;
	height: 740px;
}

.tm-section-search-container {
	position: absolute;
	bottom: 20px;
}

.tm-search-result-filter-container {
	margin-bottom: 75px;
}

#tm-section-5 { background-image: url('img/bg-img-2.jpg'); }

#tm-section-6 {
	background-image: url('img/bg-img-3.jpg');
	background-position: top center;
	padding-top: 120px;
	padding-bottom: 60px;
}

.tm-bg-img {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
            justify-content: center
}

.qs-datepicker {
	font-family:  'Source Sans Pro', Helvetica, Arial, sans-serif;
	font-size: 0.8rem;
}

.form-control {
	border-radius: 0;
	padding: 0.6rem 0.75rem;
}

.form-control:focus {
	border-color: #006666;
	box-shadow: 0 0 0 0.2rem rgba(238,80,87,.25);
}

.btn-primary {
	background-color: #006666;
	border-color: #006666;
	border-radius: 0;
	cursor: pointer;
	font-size: 1rem;
	font-weight: 500;
	padding: 10px 30px;
	text-transform: uppercase;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
	background-color: #006666;
	border-color: #006666;
}

.tm-search-form .tm-form-element { 
	padding: 0 5px; 
	font-size: 18px;
}
.tm-form-element { position: relative; }
.tm-form-element:last-child { margin-right: 0; }

.tm-form-element-icon {
	color: #006666;
	position: absolute;
	top: 10px;
	left: 15px;
}

.tm-form-element-icon-small {
	top: 16px;
	left: 18px;
}

select.tm-select.form-control:not([size]):not([multiple]) { height: 100%; }

select:not([multiple]) {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: right 50%;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
    padding: .5em;
    padding-right: 1.5em
}

/* https://stackoverflow.com/questions/20163079/remove-select-arrow-on-ie */
select.tm-select::-ms-expand { display: none; }

.tm-select {
    border-radius: 0;
    color: #858789;
}

.tm-search-form .form-control {
	font-size: 0.8rem;	
	padding: 0.75rem 0.75rem 0.75rem 45px;
}

.tm-search-form { 
	margin-right: 0;
	margin-left: 0; 
}
.tm-search-form .tm-form-element { width: 33.3333%; }
.tm-search-form .tm-form-element-2 { width: 25%; }
.tm-search-form-row { width: 900px; }
.tm-btn-search { width: 100%; }

/* https://css-tricks.com/creating-non-rectangular-headers/ */
.tm-section-down-arrow {
  position: absolute;
  top: -1px;
  width: 100%;
  height: 100px;
}

.tm-pt-5 { padding-top: 150px; }
.tm-pb-4 { padding-bottom: 100px; }

.tm-section-2 {
	background: #006666;
	padding-top: 35px;
	padding-bottom: 35px;
	position: relative;
}

.tm-section-title {
	color: white;
	font-size: 3rem;
}

.tm-section-title-2 {
	font-size: 3.2rem;
    font-weight: 600;
}

.tm-section-subtitle {
	font-size: 1rem;
	font-weight: 300;
	margin-bottom: 25px;
}

.tm-section-subtitle-2 { font-size: 1.8rem; }

.tm-btn-white-bordered {
	display: inline-block;
	padding: 10px 25px;
	border: 2px solid white;
	background: transparent;
	text-transform: uppercase;
}

.tm-btn-white-bordered:hover,
.tm-btn-white-bordered:focus {
	color: #006666;
	background: white;	
}

.slick-dots { bottom: -35px; }

.tm-article {
	padding: 40px;
	transition: all 0.3s ease;
}

.tm-article:hover {
	-webkit-box-shadow: 0px 0px 7px 0px rgba(214,214,214,1);
	-moz-box-shadow: 0px 0px 7px 0px rgba(214,214,214,1);
	box-shadow: 0px 0px 7px 0px rgba(214,214,214,1);
	transform: scale(1.1);
}

.tm-article-title-1 {
	font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 20px;
}

.tm-article-title-2 { 
	font-size: 1rem;
	font-weight: 700;
	letter-spacing: 1px;
}

.tm-article-title-3 { font-size: 1.1rem; }

.tm-btn-primary {
	display: block;
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 0.8rem;
}

.tm-sidebar-title {
	font-size: 1.3rem;
	margin-bottom: 5px;
}

.tm-sidebar-item-title {
	font-size: 1.2rem;
	letter-spacing: 1px;
	margin-bottom: 0;	
}

.tm-recommended-item .tm-bg-gray { transition: all 0.3s ease; }
.tm-recommended-item:hover .tm-bg-gray { background: #DDD; }

.tm-recommended-item h4 { 
	color: black; 
	transition: all 0.3s ease;
}

.tm-recommended-item:hover h4 { color: #006666; }
.tm-media { align-items: stretch; }

.tm-media,
.tm-media-1 {
	margin-bottom: 15px;
}

.tm-media:last-child,
.tm-media-1:last-child {
	margin-bottom: 0;
}

.tm-media-body,
.tm-media-body-1 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	        align-items: center;
	padding-left: 25px;
}

.tm-media-body-v-center {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
            justify-content: center;
    		align-items: flex-start;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 515px;
    z-index: 1;
    background-color: rgba(0,0,0,0.75);
    display: flex;
    align-items: center;
    justify-content: center;
}

.tm-bg-video { 
	position: relative; 
	padding-top: 515px;
}

video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
    background-size: cover;
    transition: 1s opacity;
}

.tm-btn-pause { display:none; }

.tm-btn-play,
.tm-btn-pause {
	color: rgba(255,255,255,0.5);
	cursor: pointer;
}

.tm-media-container { max-width: 772px; }


.tm-form-element-long {
	width: 75% !important;
	max-width: 100% !important;
}

.tm-form-element-long p {
	font-size: 21px;
}

#google-map {
    height: 479px;
    width: 100%;    
}

.contact-form .form-control {
	background-color: #F4F4F4;
	border: none;
	font-size: 0.8rem;
	font-weight: 300;
}

.clearfix:after {
   content: " "; /* Older browser do not support empty content */
   visibility: hidden;
   display: block;
   height: 0;
   clear: both;
}

/* Single Post */
.single h1,
.article h1 {
	font-size: 3rem;
	padding: 15px 0px;
}

.single h2,
.article h2 {
	font-size: 1.8rem;
	color: #000;
	line-height: 1.4em;
}

.single h3,
.article h3 {
	font-size: 1.4rem;
	color: #000;
}

.single p,
.article p {
	font-size: 1.1rem;
	line-height: 1.8em;
	color: #2a2a2a;
}

.article div.wp-caption {
	background-color: #E4F2EF;
	width: 100% !important;
}

.article div.wp-caption img,
.article img {
	width: 100%;
	height: auto;
}

.article div.wp-caption p.wp-caption-text,
.article div.gallery .wp-caption-text {
	padding: 10px;
	font-size: 12px;
	line-height: 1.0rem;
}

.article div.gallery .wp-caption-text {
	margin-bottom: 0;
}

.single .details-box {
	background: #E4F2EF;
	margin-bottom: 20px;
}

.single .details-box .details-box-inner {
	padding: 22px 25px;
}

.single .details-box h3 {
	font-size: 20px;
	color: #006666;
	text-transform: uppercase;
	font-weight: bold;
	padding-top: 10px;
}

.single .details-box p {
	font-size: 18px;
	line-height: 1.6em;
	color: #000;
	margin: 0;
}

.single .detail-btn {
	color: #fff;
	font-size: 20px;
	text-transform: uppercase;
	text-align: center;
	display: block;
	background: #72BAB0;
	font-weight: bold;
	padding: 20px;
}

.search-header,
.blog-archive-header {
	background-image: url('img/opelrock.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	max-height: 400px;
	vertical-align: middle;
}

.search-header .container,
.blog-archive-header .container {
	height: 120px;
}

.search-header .container h1,
.blog-archive-header .container h1 {
	margin: 0;
	line-height: 120px;
	color: #fff;
}

/* Home Cards */

.tm-container-outer .tab-content{
	padding-top: 40px;
	padding-bottom: 80px;
}

.tm-container-outer .tab-content .btn-primary {
	margin-top: 20px;
}


@media screen and (min-width: 992px) {
	.navbar-brand {
		padding-top: 0.6125rem; 
	}
}

@media screen and (max-width: 991px) {
	.tm-top-bar.active, .tm-top-bar, .tm-top-bar-bg { height: 48px; }
	.tm-top-bar.active .navbar-expand-lg .navbar-nav .nav-link,
	.tm-top-bar .navbar-expand-lg .navbar-nav .nav-link {
	    padding: 12px 15px;
	}
	#mainNav {
		width: 180px;
	    position: absolute;
	    top: 48px;
	    right: 15px;

		-webkit-box-shadow: 0px 0px 7px 0px rgba(214,214,214,1);
		-moz-box-shadow: 0px 0px 7px 0px rgba(214,214,214,1);
		box-shadow: 0px 0px 7px 0px rgba(214,214,214,1);
	}

	.tm-top-bar .header-slogan-container {
		width: 38%;
	}

	.tm-top-bar .header-slogan {
		font-size: 0.8rem;
		line-height: 12px;
		padding-top: 0;
		padding-bottom: 2px;
	}

	.tm-top-bar .navbar-brand div {
		width: 108px !important;
		margin-right: 0;
	}

	.tm-search-form .tm-form-element { 
		width: 33%; 
		max-width: 215px;
	}

	.tm-search-form-row { width: 98%; max-width: 900px; }
	.tm-recommended-container { margin-top: 30px; }

	.search-outside-link {
		display: none;
	}

	video {
		top: 25%;
		left: 0;
	    min-width: 50%;
	    min-height: 50%;
	    width: auto;
	    height: auto;	 
	}
}

@media screen and (max-width: 767px) {
	.tm-search-form .tm-form-element {
		width: 50%;
		max-width: 235px;
	}

	#tm-section-1 {
		padding-left: 15px;
		padding-right: 15px;
	}

	.tm-media-container,
	.tm-media-title-container {
	    max-width: 320px;
	    margin-right: auto;
	}

	.tm-media-title-container {
		margin-left: auto;
		margin-bottom: 20px;
	}

	.tm-media-1 {
		flex-direction: column;
		max-width: 280px;
		margin-bottom: 30px;
		margin-left: auto;
		margin-right: auto;
	}

	.tm-media-body-1 {
		padding-left: 0;
		margin-left: 0;
		margin-top: 20px;
	}

	.tm-bg-video { padding-top: 255px; }
	.overlay { height: 255px; }
}

@media screen and (max-width: 767px) and (min-width: 524px) {
	.tm-search-form .tm-form-element-100 { 
		width: 100%; 
		max-width: none;
	}

	.tm-search-form .tm-form-element-50 { width: 50%; }
}

@media screen and (max-width: 524px) {
	.tm-section-pad-2 { padding: 20px 25px; }

	.tm-search-form .tm-form-element {
		width: 100%;
		max-width: 100%;
	}

	.tm-fx-col-xs { flex-direction: column; }
	.tm-section-pad { padding: 25px 15px; }
	#tm-section-1 { height: 600px; }
}

/* IE Fixes */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
     /* IE10+ CSS styles go here */
     .ie-h-align-center-fix {
     	margin-left: 0;
     	margin-right: 0;
     }

     .ie-10-ml-auto { float: right; }
     .tm-media-body, .tm-media-body > * { width: 100%; }
     .tm-media-body-1, .tm-media-body-1 > * { width: 100%; }
     .tm-media-1 > * { flex: 1 1 auto; }
}

@media all and (max-width: 1199px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.ie-container-width-fix { width: 960px; }
}

@media all and (max-width: 600px) {
	.ie-container-width-fix-2 { width: 100%; }
}

@media all and (max-width: 560px)  and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.ie-container-width-fix {
		max-width: 530px;
		width: 100%;
	}	
}

@media screen and (max-width: 575px) {
	.card-deck .col {
		flex: 1 0 100%;
		width: 100%;	
		margin: 0 !important;
		margin-bottom: 15px !important;
	}
}

@media screen and (min-width: 576px) {
	.card-deck .col-sm-6 {
		flex: 1 0 48%;
		max-width: 50%;
	}
}

@media screen and (min-width: 992px) {
	.card-deck .col-lg-3 {
		flex: 1 0 24%;
		max-width: 25%;
	}	
}

.filter-controls ul.list-group li {
	cursor: pointer;
}

.filter-controls ul.list-group li.active {
	background-color: #006666;
	border-color: #006666;
	z-index: 0;
}

/* Full Width Featured */
/* Decide if we need to animate faster/slower or not at all */
.featured-full-width {

}

.featured-full-width.animated-img {
	background-position: 0px 0px;
	animation: animatedBackground 30s linear infinite alternate;
}
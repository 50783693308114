/* Main content formating */
.content {
    min-height: 85vh;
}

/* Formatting all text link this way */
a {
    color: #000;
    text-decoration: underline;
}

a.nav-link,
a.btn-primary,
a.detail-btn {
    text-decoration: none;
}

/* Tabs */
.tm-tabs-links {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: space-evenly;
    -webkit-justify-content: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    background: #69c6ba;
}

.tm-tab-link {
    padding: 30px 25px;
    text-align: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-transform: uppercase;
    color: white;
    font-weight: 400;
    font-size: 0.9rem;
    height: 100%;
}

.tm-tab-link:hover { color: white; }

.tm-tab-link-li {
    background: transparent;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    transition: all 0.3s ease;    
}

.tm-tab-link:hover,
.tm-tab-link.active {
    background: #c66995;
}

.tab-pane {
    max-width: 970px;
    margin: 60px auto;
}

.tm-recommended-place {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: stretch;
    justify-content: space-around;
    margin-bottom: 25px;
}

.tm-recommended-place .tm-recommended-description-box{
    padding: 30px;
}

@media screen and (max-width: 826px) {
    .tm-recommended-place .tm-recommended-description-box{
        padding: 10px;
    }   
}

.tm-recommended-title {
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0px;
}

.tm-recommended-img { width: 270px; }

.tm-recommended-description-box {
    padding: 30px;
    width: 100%;
}

.tm-recommended-price-box {    
    background-color: #69c6ba;
    background-image: url(img/button-curve.png);
    background-repeat: no-repeat;
    background-size: auto 100%;
    font-weight: 400;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 20px;
    width: 200px;
    transition: all 0.3s ease;
}

.tm-recommended-price-box:hover { background-color: #c66995; }

.tm-recommended-price {
    color: white;
    font-size: 1.6rem;
    font-weight: 600;
}

.tm-recommended-price-link {
    color: white;
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
}

.tm-d-table { display: table; }


.tm-tab-link-li {
    background: transparent;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    transition: all 0.3s ease;    
}

@media screen and (max-width: 1080px) {
    .tm-tab-link-li { width: 25%; }
}


.tab-pane {
    max-width: 970px;
    margin: 60px auto;
}

@media screen and (max-width: 991px) {

    .tab-pane {
        margin-left: 20px;
        margin-right: 20px;
    }

    .tm-recommended-description-box { }
}


@media screen and (max-width: 826px) {

    .tm-recommended-place-wrap {
        display: flex;
        flex-wrap: wrap;
        max-width: 600px;
        margin: 0 auto 15px;
    }

    .tm-recommended-place {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

}

.tm-recommended-description-box {
    padding: 30px;
}

.tm-text-highlight {
    color: #006666;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0px;
}

.tm-text-gray { color: #787676; }

.tm-recommended-title {
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;    
}

.tm-section-reduced {
    max-height: 315px;
    background: #245E52;
}

.tm-bg-dark-black {
    background: #000;
}

/* Search Page Elements */

.section-search {
    padding: 20px 0px;
}


@media screen and (max-width: 780px) {
    .search-header {
        height: 400px;
        max-height: 400px;
    }

    .search-header h1{
        text-align: center;
    }
}

.section-search .tm-btn-search {
    background: #fff;
    color: #000;
}

.section-search .tm-btn-search:hover {
    background: #fff;
}

div.bp a.text-uppercase.btn-primary.tm-btn-primary {
    width: 77%;
    margin-right: 0;
}

@media screen and (max-width: 1199px) {
    div.bp a.text-uppercase.btn-primary.tm-btn-primary {
        width: 72%;
    }
}

@media screen and (max-width: 826px) {
    div.bp a.text-uppercase.btn-primary.tm-btn-primary {
        width: 100%;
    }
}

.DayPickerInput {
    width: 100%;
}

@media screen and (max-width: 524px) {

    .DayPickerInput {
        display: block;
        width: 100%;
    }

    .section-search .box-search .form-group  {
        width: 100% !important;
    }

    .section-search .box-search .form-group:first-child {
        width: 100% !important;
    }
}


@media screen and (min-width: 980px) {
    .section-search .box-search .form-group  {
        width: 20% !important;
    }

    .section-search .box-search .form-group:first-child {
        width: 40% !important;
    }

    .search-header .section-search .box-search .form-group,
    .search-header .section-search .box-search .form-group:first-child {
        width: 33% !important;
    }
}

/* Box Filter */
.box-filters {
    display: block;
    background: #006666;
    margin-top: 10px;
    margin-bottom: 10px;
}

.box-filters h3 {
    display: block;
    background: #002828;
    color: #fff;
    font-size: 18px;
    padding: 5px 10px;
}

.box-filters input {
    display: inline-block;
    width: 20px;
    float: left;
    color: #fff;
    margin-top: 5px;
}

.box-filters label {
    display: inline-block;
    color: #fff;
}

.box-filters .filter-controls-items {
    padding: 10px 7.5px;
}

.box-filters .filter-controls-items input{
    display: none;
}

.box-result {
    margin-top: 12px;
}

/* 404 Page */
.page-404 {
    background: #006666;
    text-align: center;
    margin: 40px auto;
}

.page-404 h1, .page-404 p{
    color: #fff;
}

.page-404 h1 {
    font-size: 72px;
}

.page-404 p {
    font-size: 24px;
}

/* Pagination Style */

.page-link {
    color: #006666;
}

.page-link:hover {
    color: #006666;
}

.page-item.active .page-link {
    border-color: #006666;
    background: #006666;
}

/* Filter Control */
.list-group-item:last-child {
    border-radius: 0;
}
.list-group-item:first-child {
    border-radius: 0;
}

/* Form Styling */
.tm-search-form .form-group .form-control-select {
    padding: 3px 3px 2px 45px;
}

/* Listing Border IMG */
.card-img-top {
    border-top-right-radius: 0;
    border-bottom-left-radius: calc(.25rem - 1px);
}

/* Banner Styling */
.banner-container{
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
}

.banner-container .banner-link{
    margin-left: auto;
    margin-right: auto;
}

.banner-container .banner-link .banner-img{
    max-width: 1140px;
    width: 100%;
    height: auto;
}

/* Search Result */
.box-result .tm-recommended-place img.bp,
.box-result .tm-recommended-place div.bp,
.box-result .tm-recommended-place img.pp,
.box-result .tm-recommended-place div.pp
{
    height: 270px;
}


@media screen and (min-width: 827px) {
    .box-result .tm-recommended-place img.pp,
    .box-result .tm-recommended-place div.pp{ /* pp : premium post */
        max-width: 300px;
        max-height: 270px;
    }

    .box-result .tm-recommended-place img.bp,
    .box-result .tm-recommended-place div.bp{ /* bp : basic post */
        max-width: 165px;
        max-height: 135px;        
    }

    .box-result .tm-recommended-place img.bp,
    .box-result .tm-recommended-place div.bp,
    .box-result .tm-recommended-place img.pp,
    .box-result .tm-recommended-place div.pp{
        height: auto;
    }
}

/* Single : Basic */
.single.basic {
    margin-top: 20px;
}

/* Single : Premium */

.single.premium .featured-full-width {
    position: relative;
    width: 100%;
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    /* -webkit-animation: zoomout 10s ease-in infinite;
    animation: zoomout 10s ease-in infinite;
    transition: all .5s ease-in-out; */
    overflow: hidden;
}

.single.singe.premium .featured-full-width-zoom {
    position: relative;
    height: 500px;
    /* -webkit-animation: zoomin 10s ease-in infinite;
    animation: zoomin 10s ease-in infinite;
    transition: all .5s ease-in-out; */
    overflow: hidden;
}

.gallery {
    line-height: 0;
    -webkit-column-count: 2;
    -webkit-column-gap: 0px;
    -moz-column-count: 5;
    -moz-column-gap: 0px;
    column-count: 2;
    column-gap: 0px;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
}

dl.gallery-item {
    width: 50%;
}

dl.gallery-item,
img.attachment-medium.size-medium {
    margin-bottom: 0;
}

dd.wp-caption-text.gallery-caption {
    line-height: 2.0rem;
    padding-left: 5px;
    padding-right: 5px;
    background: #E4F2EF;
}

/* Zoom in Keyframes */
@-webkit-keyframes zoomout {
    0% {
        transform: translate(0px);
    }
    50% {
        transform: translate(20px);
    }
    100% {
        transform: translate(0px);
    }
}
@keyframes zoomout {
    0% {
        transform: translate(0px);
    }
    50% {
        transform: translate(20px);
    }
    100% {
        transform: translate(0px);
    }
} /*End of Zoom in Keyframes */

/* Zoom out Keyframes */
@-webkit-keyframes zoomin {
    0% {
        transform: translate(40px);
    }
    50% {
        transform: translate(20px);
    }
    100% {
        transform: translate(0px);
    }
}
@keyframes zoomin {
    0% {
        transform: translate(40px);
    }
    50% {
        transform: translate(20px);
    }
    100% {
        transform: translate(0px);
    }
}/*End of Zoom out Keyframes */